import React from 'react';
import { Link } from 'gatsby';

import defaultImage from '../images/background.jpg';


const Banner = ({ title, description, button, image, alignment, theme }) => {

  // If no Text content is set, do not add dark overlay to banner image.
  let hasText;
  if(title || description || button){
    hasText = true;
  }

  return (
    <div className={`banner ${hasText && "banner-overlay"}`} style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: `${alignment}` }}>
    {hasText && 
      <div className="banner-container">
        {title && (
          <div className="banner-title">{ title }</div>
        )}
        {description && (
          <div className="banner-description">{ description }</div>
        )}
        {button && button.url && button.title && (
          <div className="banner-button">
            <Link to={button.url}><button>{button.title}</button></Link>
          </div>
        )}
      </div>
    }
    </div>
  )
}

export default Banner;


// Set default props
Banner.defaultProps = {
  image: defaultImage,
  alignment: "center",
  theme: "light"
};