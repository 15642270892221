import React from "react";
import logo from "../images/logo.png";
import { Link } from 'gatsby';

const Header = (props) => {
  return (
    <div className="header">
        <div className="header__wrapper">
            <div className="header__logo">
                <Link to="/">
                    <img src={logo} alt="PBD Logo" />
                </Link>
            </div>
            <div className="header__nav">
                <nav>
                    <a className="nav__link" href="/">Products</a>
                    <a className="nav__link" href="/about">About</a>
                    <a className="nav__link nav__link--bubble" href="/contact">Contact Us</a>
                </nav>
            </div>
        </div>
    </div>
  );
};


export default Header;
