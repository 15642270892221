import React from "react"
import Layout from '../components/layout';
import Banner from '../components/banner';
import background from '../images/background.jpg';

const NotFoundPage = () => (
  <Layout>
    <Banner
      title="Page Not Found"
      description="Sorry, we are unable to find the page you were looking for."
      button={{
        title: "Return Home",
        url: "/"
      }}
      image={background}
    />
  </Layout>
)

export default NotFoundPage



